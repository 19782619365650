import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css'; 
import 'select2-theme-bootstrap4/dist/select2-bootstrap.css'; 

// $(() => {
//   $('.select2').select2({
//     theme: "bootstrap",
//   });
//   $('.select2-4').select2({
//     theme: "bootstrap4",
//   });
// })
// window.addEventListener('DOMContentLoaded', () => {
document.addEventListener('turbolinks:load', () => {
  $('.select2').select2()
})